<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.70675 0.892746C8.60326 -0.185546 11.1456 0.115441 12.6973 1.62622C13.6879 2.57301 14.249 3.91138 14.249 5.29066H12.8697C12.8258 4.17146 12.3086 3.04933 11.4057 2.35969C10.155 1.4129 8.25844 1.4129 7.01066 2.35969C6.10478 3.04933 5.58755 4.17146 5.54372 5.29066H4.16444C4.16444 3.52273 5.15507 1.75772 6.70675 0.892746ZM0.5 7.01183H1.87928C2.35268 10.0305 2.8699 13.0462 3.43097 16.0619H14.9824C15.4997 13.0433 16.0607 10.0275 16.5341 7.01183H17.9134C17.3085 10.5039 16.7504 13.9959 16.1893 17.485H2.2241C1.66304 13.9959 1.06106 10.5039 0.5 7.01183Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
