<script lang="tsx">
import { defineComponentBaseUiButton } from '@core/app/components/base/ui/BaseUiButton.vue'

type Colors = 'basic' | 'primary' | 'primary-light'
type Variants = 'solid' | 'solid-shadow' | 'plain'
type Sizes = 'extra-small' | 'small' | 'medium' | 'n-medium'

export default defineComponentBaseUiButton<Colors, Variants, Sizes>({
    props: {
        color: {
            default: 'basic',
        },
        variant: {
            default: 'solid',
        },
        size: {
            default: 'medium',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiButton.scss" as *;

@include button {
    border-radius: $mon-border-radius;

    transition: background-color $mon-trans-time-normal $mon-timing, color $mon-trans-time-normal $mon-timing;
}

@include button--hover {
    transition: background-color $mon-trans-time-short $mon-timing, color $mon-trans-time-short $mon-timing;
}

@include button--disabled {
    background-color: $mon-c-basic-200;
    color: $mon-c-white;
}

@include button--variant('solid-shadow') {
    box-shadow: $mon-box-shadow;
}

// --------------------------------------------------------------------
// Button colors

@include button--color('basic') {
    @include button--variant('solid', '&') {
        background-color: $mon-c-white;
        color: $mon-c-black;

        @include button--hover('&') {
            background-color: $mon-c-primary-100;
        }
    }

    @include button--variant('solid-shadow', '&') {
        background-color: $mon-c-white;
        color: $mon-c-black;

        @include button--hover('&') {
            background-color: $mon-c-primary-100;
        }
    }
}

@include button--color('primary') {
    @include button--variant('solid', '&') {
        background-color: $mon-c-primary-500;
        color: $mon-c-white;

        @include button--hover('&') {
            background-color: #413573;
        }
    }

    @include button--variant('solid-shadow', '&') {
        background-color: $mon-c-primary-500;
        color: $mon-c-white;

        @include button--hover('&') {
            background-color: #413573;
        }
    }

    @include button--disabled('&') {
        background-color: $mon-c-primary-300;
        color: $mon-c-white;
    }
}

@include button--color('primary-light') {
    @include button--variant('solid', '&') {
        background-color: $mon-c-primary-100;

        @include button--hover('&') {
            background-color: $mon-c-primary-200;
        }
    }

    @include button--variant('solid-shadow', '&') {
        background-color: $mon-c-primary-100;

        @include button--hover('&') {
            background-color: $mon-c-primary-200;
        }
    }

    @include button--disabled('&') {
        background-color: $mon-c-primary-200;
        color: white;
    }
}

// --------------------------------------------------------------------
// Button sizes

@include button--size(extra-small) {
    width: 1.75rem;
    height: 1.75rem;
}

@include button--size(small) {
    width: 2.5rem;
    height: 2.5rem;

    @include more-than(lg) {
        width: 3rem;
        height: 3rem;
    }
}

@include button--size(medium) {
    padding: 1rem;
    width: 3.25rem;
    height: 3.25rem;

    @include more-than(lg) {
        width: 3.75rem;
        height: 3.75rem;
    }
}

@include button--size('n-medium') {
    width: 3rem;
    height: 3rem;

    padding: 0.75rem;
    margin: -0.75rem;
}

</style>
